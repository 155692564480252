<template>
    <div class="pr-md-0 w-100">
        <div class="text-center" v-if="enableTfaModel.stepIndex === 1">
            <VuePerfectScrollbar style="max-height: calc(80vh - 5rem) !important; width: 100%; ">
                <div class="w-100">
                    <div class="d-flex flex-column flex-md-row align-items-center  justify-content-center">
                        <div style="width: 28rem; height: auto; border: 1px solid #e0e0e0; max-width: 95% !important;">
                            <img :src="enableTfaModel.twoFactorAuthInfo.barcodeImageUrl" class="w-100" />
                        </div>
                        <div class="ml-3 d-flex flex-column align-items-center justify-content-center">
                            <div class="mb-1 mt-2 mt-md-0 text-center">
                                <span class="text-muted">
                                    Use <strong class="text-navy">Google Authenticator</strong> app to scan.<br /><span class="d-none d-md-inline">After that, click <strong class="text-navy">Next</strong> to continue..</span>
                                </span>
                            </div>
                            <small>Or enter this code in <strong class="text-navy">Google Authenticator</strong> app</small>

                            <div class="mt-2 "
                                 style="line-height: 1.2rem !important; word-break: break-all; user-select: text; ">
                                <strong>{{ enableTfaModel.twoFactorAuthInfo.setupCode }}</strong>
                            </div>
                            <div class="mt-2 text-danger d-none d-md-block" style="line-height: 1rem">
                                <small>
                                    Make sure full page refesh to clear browser cache after update
                                    two-factor authencation <br />Press Ctrl+F5 (Windows, Ubuntu) or
                                    Command+R (Mac OS)
                                </small>
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr />
                        <el-button type="primary" size="small"
                                   :disabled="enableTfaModel.twoFactorAuthInfo.setupCode === ''"
                                   @click="showPinCodeToEnable">
                            Next step
                        </el-button>
                    </div>
                </div>
            </VuePerfectScrollbar>
        </div>
        <div class="text-center w-100" v-if="enableTfaModel.stepIndex === 2">
            <div class="m-auto">
                <div class="text-center w-100">
                    <p>
                        Type 6-digit code from <strong class="text-navy">Google Authenticator</strong> to verify your settings
                    </p>
                </div>
                <div class="text-center PIN-CODE"
                     style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">
                    <CodeInput :loading="false"
                               class="input m-auto"
                               v-on:change="onPINChange"
                               v-on:complete="onPINComplete"
                               :autoFocus="true" />
                </div>
                <div class="text-center text-danger mt-2" v-if="enableTfaModel.pinSubmitError">
                    {{ enableTfaModel.pinSubmitError }}
                </div>
                <div class="mt-4 mb-4">
                    <el-button @click="step2CancelClick">
                        Cancel
                    </el-button>
                    <el-button type="primary"
                               :disabled="!enableTfaModel.pinInputCompleted"
                               @click="step2SubmitClick">
                        Confirm
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import commonAct from "@/commonActionHandle.js";
    import baseComponent from "@/scripts/baseComponent";
    import authApi from "@/api/common/auth";
    import CodeInput from "vue-verification-code-input";
    export default {
        extends: baseComponent,
        components: {
            CodeInput
        },
        data() {
            return {
                isLoading: false,
                enableTfaModel: {
                    stepIndex: 1,
                    isShowTwoFactorAuthCode: false,
                    cancelPINDialogAction: null,
                    submitPINDialogAction: null,
                    pinInputCompleted: false,
                    pinInputValue: '',
                    pinSubmitError: null,
                    twoFactorAuthInfo: {
                        setupCode: '',
                        barcodeImageUrl: ''
                    },
                    enableTwoFactorAuth: {
                        twoFactorAuthentionCode: '',
                        deviceInformation: null,
                    }
                },
            }
        },
        created() {
            this.getTwoFactorAuthInfo();
        },
        methods: {
            getTwoFactorAuthInfo() {
                this.isLoading = true;
                authApi
                    .getEnableTwoFactorAuthInfo()
                    .then((response_data) => {
                        if (response_data.data && response_data.data.result === 0) {
                            this.$set(this.enableTfaModel.twoFactorAuthInfo, 'setupCode', response_data.data.data.setupCode);
                            this.$set(this.enableTfaModel.twoFactorAuthInfo, 'barcodeImageUrl', response_data.data.data.barcodeImageUrl);
                            this.isLoading = false;
                        } else {
                            this.isLoading = false;
                            this.resetAll();
                            if (
                                Array.isArray(response_data.data.data) &&
                                response_data.data.data.length > 0
                            ) {
                                commonAct.showError(response_data.data.data[0]);
                                return;
                            }
                            if (
                                response_data.data &&
                                response_data.data.message !== null &&
                                response_data.data.message !== ""
                            ) {
                                commonAct.showError(
                                    response_data.data.message || this.$lang.common.error
                                );
                            }
                        }
                        this.hideLoading();
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.resetAll();
                        console.error(error);
                        this.hideLoading();
                        commonAct.showError(error);
                    });
            },
            showPinCodeToEnable() {
                this.isLoading = true;
                this.showPinCodeInput(() => {
                    //this.enableTfaModel.isEnabledTwoFactorAuth = false;
                }, () => {
                    this.submitEnableTfa();
                });
            },
            showPinCodeInput(cancelAction, confirmAction) {
                this.enableTfaModel.cancelPINDialogAction = cancelAction;
                this.enableTfaModel.submitPINDialogAction = confirmAction;
                this.enableTfaModel.stepIndex = 2;
                this.$nextTick(() => {
                    this.isLoading = false;
                    $('.PIN-CODE .react-code-input > input:first-child').focus();
                });
            },
            onPINChange(v) {
                if (v.length < 6) {
                    this.enableTfaModel.pinInputCompleted = false;
                    this.enableTfaModel.pinInputValue = v;
                }
            },
            onPINComplete(v) {
                if (v.length === 6) {
                    this.enableTfaModel.pinInputCompleted = true;
                    this.enableTfaModel.pinInputValue = v;
                }
            },
            resetAll() {
                this.enableTfaModel.pinInputCompleted = false;
                this.enableTfaModel.pinInputValue = '';
                this.enableTfaModel.centerDialogVisible = false;
                this.enableTfaModel.pinSubmitError = null;
                $('.PIN-CODE .react-code-input > input').val('');
                $('.PIN-CODE .react-code-input > input:first-child').focus();
                this.isLoading = false;
            },
            step2CancelClick() {
                this.enableTfaModel.stepIndex = 1;
                if (this.enableTfaModel.cancelPINDialogAction) this.enableTfaModel.cancelPINDialogAction();
            },
            step2SubmitClick() {
                if (this.enableTfaModel.submitPINDialogAction) this.enableTfaModel.submitPINDialogAction();
            },
            submitEnableTfa() {
                this.isLoading = true;
                authApi
                    .postEnableTwoFactorAuth({
                        twoFactorAuthentionCode: this.enableTfaModel.pinInputValue,
                        deviceInformation: JSON.stringify(this.getInformationClient())
                    })
                    .then((response_data) => {
                        if (response_data.data && response_data.data.result === 0) {
                            this.$message({
                                message: 'Congrats, enable two-factor authencation succeed.',
                                type: 'success'
                            });
                            this.$emit('enableTfaCompleted');
                            this.isLoading = false;
                            this.resetAll();
                        } else {
                            this.isLoading = false;
                            if (
                                Array.isArray(response_data.data.data) &&
                                response_data.data.data.length > 0
                            ) {
                                commonAct.showError(response_data.data.data[0]);
                                return;
                            }
                            if (
                                response_data.data &&
                                response_data.data.message !== null &&
                                response_data.data.message !== ""
                            ) {
                                commonAct.showError(
                                    response_data.data.message || this.$lang.common.error
                                );
                            }
                        }
                        this.hideLoading();
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.error(error);
                        this.hideLoading();
                        commonAct.showError(error);
                    });
            },
        }
    }
</script>

<style>
    .react-code-input > input {
        font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
    }
</style>